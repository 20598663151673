<template>
  <div class="post-body">发布通道暂未开启</div>
</template>

<script>
export default {

}
</script>

<style scoped>
  .post-body{
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
    justify-content: space-around;
    flex-direction: column;
    color: #ec4899;
    font-size: 24px;
  }
</style>