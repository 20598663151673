<template>
  <div id='container'>
     <div class="header">
        <van-nav-bar
        title="推广赚钱"
        left-text="返回"
        right-text=""
        left-arrow
        @click-left="onClickLeft"
        />
    </div>
    <div class="content">
        <div class="topBox">
            <div class="top">
                <div class="header">
                    <div class="left">
                        <img style="width: 100%;height: 100%;" src="../../assets/images/uugai.com-1032126-16719525801216.png" alt="">
                    </div>
                    <div class="right">
                        <div style="text-align: start;">良家楼凤的聚集地</div>
                        <div>分享你我的性爱生活</div>
                    </div>
                </div>

            </div>
            <div class="center">
                <div class="ercode" id="qrCode" ref="qrCode">

                </div>
            </div>
            <div class="tips">
                <div>分享好友赚积分领会员</div>
                <div>邀请码:<span style="font-size: 14px;">{{inviteCode}}</span></div>
            </div>
            <div class="bottom">
                <div class="btn" style="width: 120px;height: 40px;display: flex;align-items: center;justify-content: center;">已邀请 {{inviteNum}} 用户</div>
                <div class="btn" @click="copy" style="width: 120px;height: 40px;display: flex;align-items: center;justify-content: center;">复制推广链接</div>
            </div>
        </div>
        <div class="jianjie">
            <div class="title">
                <span style="display: block;width: 5px;height: 20px;background: #ec4899;margin-right: 10px;"></span>
                <div>推广福利</div>
            </div>
            <div class="content">
                成功邀请1人领20积分<br>
                成功邀请38人以上领<span style="color: #ec4899;">永久会员</span><br>
                成功邀请78人以上领<span style="color: #ec4899;">超级会员</span><br>
                成功邀请108人以上领<span style="color: #ec4899;">至尊会员</span><br>
                <span style="color: #ec4899;">成功邀请一位有效会员可得佣金30(满300可提现)</span><br>
            </div>
        </div>

    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import { Toast } from 'vant';
import QRCode from "qrcodejs2"

export default {
    data() {
        return {
            inviteNum:'',
            score:"",
            salary: "",
            ermsg: "",
            inviteCode: "",
            
        }
    },
    created() {
        this.inviteNum = this.userInfo.inviteNum
        this.score = this.userInfo.score
        this.salary = this.userInfo.salary
        this.inviteCode = this.userInfo.inviteCode

        this.ermsg = `51风流-楼凤小姐兼职包养信息平台http://61hx.xyz?/code=${this.userInfo.inviteCode}`
        this.$nextTick(() => { 
        this.creatQrCode()

        })


    },
    computed:{
        // ...mapMutations(["changeFlag","changeFlag1"]),
          ...mapState({
            loginStatus : state=>state.user.loginStatus,
            userInfo : state=>state.user.userInfo
        }),
        loginStatus(){
          return this.$store.state.user.loginStatus
        },
        userInfo(){
          return this.$store.state.user.userInfo
        },

    },
    methods: {
        creatQrCode() {
            let el = document.getElementById('qrCode')
            new QRCode(el, {
                text: this.ermsg, //可以是链接，也可以是文本
                width: 125, //二维码的宽度
                height: 125, //二维码的高度
                colorLight: "#ffffff", //二维码背景色
                colorDark: "#ec4899", //二维码前景色，以实现红码为例
                correctLevel: QRCode.CorrectLevel.H, //纠错等级
            });
            },
        onClickLeft(){
            this.$router.go(-1)
        },
        goTg(){
            if(this.loginStatus){
                this.$router.push({
                    path:"/QrCode"
                })
            }else{
                 Toast.fail('请先登录！')
            }
        },
        copy(){
            if(this.ermsg!="" && this.ermsg!=null){
                this.copyText()
                Toast.success('复制成功');

            }
        },
    copyText() {
        var input = document.createElement("input");// 创建input
        input.value = this.ermsg; // 赋值
        document.body.appendChild(input); // 添加dom
        input.select(); // 选中input
        document.execCommand("Copy"); // 复制到剪贴板
        document.body.removeChild(input); // 清除input
        }
    },







    async mounted() {
    // this.ces = true
      // this.ces = true
        // this.changeFlag();
        this.$store.commit('changeFlag')
  },
  destroyed() {
      // this.changeFlag1();
      this.$store.commit('changeFlag1')
  },
}
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}
#container{
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;

}
#container .header{
    width: 100%;
    height: 60px;
    /* color: #ec4899; */
}
#container .logo{
    position: absolute;
    top: 10px;
    left: 34%;
    z-index: 99;
}
#container .logo img{
    height: 45px;
}
#container .content{
    width: 100%;
    height: calc(100% - 60px);
    overflow-y: scroll;
    padding: 0 20px;
    box-sizing: border-box;
}

 /deep/.van-nav-bar__text{
   
    color: #ec4899 !important;
}
 /deep/.van-nav-bar__title{
    color: #ec4899 !important;
    font-size: 18px;
}
/deep/.van-nav-bar .van-icon{
    color: #ec4899 !important;
}
/deep/.van-nav-bar__content{
    height: 60px !important;
}
.topBox{
    height: 360px;
    width: 100%;
    border-radius: 15px;
    background: #fff;
}
.topBox .top{
    height: 60px;
    width: 100%;
    margin-top: 20px;
 
}
.topBox .top .header{
    padding-top: 10px;
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.topBox .top .header .left{
    width: 60px;
    height: 60px;
    float: left;
}
.topBox .top .header .right{
    float: left;
    font-size: 17px;
    font-weight: 700;
    color: #8b8787;;
}
.topBox .center{
    width: 100%;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.topBox .center .ercode{
    width: 125px;
    height: 125px;
    /* background: #ec4899; */
}
.topBox .tips{
    height: 50px;
    width: 100%;
    color: #8b8787;;
    font-size: 17px;
    font-weight: 700;
}
.topBox .bottom{
    height: 60px;
    width: 100%;
    display: flex;
    font-size: 14px;
    align-items: center;
    justify-content: center;
}
.topBox .bottom .btn{
    background: #ec4899;
    color: #fff;
    border-radius: 20px;
    margin: 0 15px;
}

.content .jianjie{
    margin-top: 20px;
    height: 200px;
    width: 100%;
    /* background:#ec4899; */

}
.content .jianjie .title{
    height: 38px;
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    display: flex;
    align-items: center;
}
.content .jianjie .content{
    font-size: 14px;
    font-weight: 700;
    text-align: left;
    line-height: 26px;
    /* height: 80px; */
}
</style>