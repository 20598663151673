/****   request.js   ****/
// 导入axios

import axios from 'axios'
import { Toast } from 'vant';
import store from '../store/index'
import { getCookie } from './cookie';
import router from "../router/index"

// 使用element-ui Message做消息提醒

//1. 创建新的axios实例，
// var load = null;
const service = axios.create({
  // 公共接口--这里注意后面会讲
  // baseURL: "http://192.168.31.31:3000",
  // baseURL: "http://192.168.3.152:3000",
  // baseURL:"http://38.54.111.182:7890",
  baseURL: "http://178.157.60.44:7890",
  // baseURL: "http://192.168.248.152:3000"
  // baseURL: '/api',
  

  // 超时时间 单位是ms，这里设置了3s的超时时间
  // timeout: 1 * 1000
})
// 2.请求拦截器

service.interceptors.request.use(config => {
  //发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
   config.data = JSON.stringify(config.data); //数据转化,也可以使用qs转换
   config.headers = {
     'Content-Type': 'application/json',//配置请求头
    //  'Referer':'https://lu58.xyz'
  }
  if (store.state.user.loginStatus) { 
      if (getCookie('token')) {
        config.headers.Authorization = getCookie('token')
      } else { 
        Toast({
          message: '认证失败,请重新登陆',
          position: 'bottom',
        });
        store.commit('loginOut',store.state)
        router.push({
          path:"./login"
        })
        
        return

      }
  }
  // alert()

  // console.log();
 Toast.loading({
    duration: 500,
    message: '加载中...',
    forbidClick: true,
    color: "#ec4899",
    className: "noticeAAA",
  });
   //如有需要：注意使用token的时候需要引入cookie方法或者用本地localStorage等方法，推荐js-cookie
   //const token = getCookie('名称');//这里取token之前，你肯定需要先拿到token,存一下
   //if(token){
      //config.params = {'token':token} //如果要求携带在参数中
      //config.headers.token= token; //如果要求携带在请求头中
    //}
  return config
}, error => {
  Promise.reject(error)
})

// 3.响应拦截器
service.interceptors.response.use(response => {
  //接收到响应数据并成功后的一些共有的处理，关闭loading等
   //关闭loading
   Toast.clear();  // 清除加载
  return response
}, error => {
  Toast.clear();  // 清除加载
   /***** 接收到异常响应的处理开始 *****/
  if (error && error.response) {
    // 1.公共错误处理
    // 2.根据响应码具体处理
    switch (error.response.status) {
      case 400:
        error.Toast = '错误请求'
        break;
      case 401:
        error.Toast = '未授权，请重新登录'
        break;
      case 403:
        error.Toast = '拒绝访问'
        break;
      case 404:
        error.Toast = '请求错误,未找到该资源'
        window.location.href = "/NotFound"
        break;
      case 405:
        error.Toast = '请求方法未允许'
        break;
      case 408:
        error.Toast = '请求超时'
        break;
      case 500:
        error.Toast = '服务器端出错'
        break;
      case 501:
        error.Toast = '网络未实现'
        break;
      case 502:
        error.Toast = '网络错误'
        break;
      case 503:
        error.Toast = '服务不可用'
        break;
      case 504:
        error.Toast = '网络超时'
        break;
      case 505:
        error.Toast = 'http版本不支持该请求'
        break;
      default:
        error.Toast = `连接错误${error.response.status}`
    }
  } else {
    // 超时处理
    if (JSON.stringify(error).includes('timeout')) {
      // Toast.error('服务器响应超时，请刷新当前页')
      console.log('服务器响应超时，请刷新当前页')
    }
    error.Toast = '连接服务器失败'
  }

  Toast.error(error.Toast)
  /***** 处理结束 *****/
  //如果不需要错误处理，以上的处理过程都可省略
  return Promise.resolve(error.response)
})
//4.导入文件
export default service
