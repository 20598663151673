<template>
  <div id="container">
    <div class="header">
        <van-nav-bar
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
        />
    </div>
    <div class="logo">
        <div class="content">
            <img src="../../assets/images/uugai.com-1032126-16719525801216.png" alt="">
        </div>
    </div>
    <div class="body">
        <div class="line">
            <div class="left">
                <van-icon class="iii" size="24" name="contact" />
            </div>
            <div class="right">
                <input style="padding-left: 10px;" v-model="username" class="input_" type="text" placeholder="手机号">
            </div>
        </div>
        <div class="line">
            <div class="left">
               <van-icon class="iii" size="24" name="shield-o" />
            </div>
            <div class="right">
                <input style="padding-left: 10px;" v-model="password1" class="input_" type="password"  placeholder="密码">
            </div>
        </div>
        <div class="line">
            <div class="left">
               <van-icon class="iii" size="24" name="shield-o" />
            </div>
            <div class="right">
                <input style="padding-left: 10px;" v-model="password2" class="input_" type="password"  placeholder="确认密码">
            </div>
        </div>
        <div class="line">
            <div class="left">
                <van-icon class="iii" size="24" name="envelop-o" />
            </div>
            <div class="right">
                <input style="padding-left: 10px;" v-model="email" class="input_" type="text"  placeholder="邮箱">
            </div>
        </div>
        <!-- <div class="line">
            <div class="left">
                <van-icon class="iii" size="24" name="envelop-o" />
            </div>
            <div class="right">
                <input style="padding-left: 10px;width:130px" v-model="code" class="input_" type="text"   placeholder="验证码"><van-button id="codeBtn" :disabled="disabled" type="primary"  color="#ec4899"   style="float: right;width:50px;height: 35px;margin-top:1px;margin-right: 10px;" @click="sendCode">发 送</van-button>
            </div>
        </div> -->
  <div>
    <van-button type="primary"  color="#ec4899"   style="width:90px;margin-left:15px;margin-top:10px" @click="_debounce(onSubmit,2000)">立即注册</van-button>
  </div>
  <div style="margin-top:20px;color:#ec4899">
    <div style="font-size: 12px;">*请记住用户名,便于登陆和找回密码。</div>
    <div style="margin-top:5px;font-size: 12px;">*请填写真实邮箱,否则无法找回密码。</div>
  </div>
    </div>
    <!-- <div class="logo">
        <img src="../../assets/images/title.png" alt="">
    </div>
    <div class="login">
        
<van-form>
  <van-field
    v-model="username"
    name="username"
    label="用户名:"
    placeholder="请输入手机号码" 
    :rules="telRules"
    
  />
  <van-field
    v-model="password1"
    type="password"
    name="password1"
    label="密码:"
    placeholder="请输入密码" 
    :rules="pwdRules"
   
  />
    <van-field
    v-model="password2"
    type="password"
    name="password2"
    label="确认密码:"
    placeholder="确认密码" 
    :rules="pwdRules"
    
  />
    <van-field
    v-model="email"
    type="email"
    name="email"
    label="邮箱:"
    placeholder="请输入邮箱" 
    :rules="emailRules"
  />
</van-form> -->
  <!-- <div>
    <van-button type="primary"  color="#ec4899"   style="width:90px;margin-top:170px" @click="_debounce(onSubmit,2000)">立即注册</van-button>
  </div> -->

    <!-- </div> -->
  </div>
</template>
<script>
import { Dialog } from 'vant';
import { Toast } from 'vant';
import { register,num,ipAdress,getRegCode } from "../../model/user"
import { getNumByIP } from "../../model/index"
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid';



// import { mapMutations } from "vuex"
export default {
    data() {
        return {
            countdown :60,
            disabled:false,
            isCode:true,
            inviteCode:"",
            createTime:'',
            ipNum:"",
            userIp:"",
            username: '',
            password1: '',
            password2: '',
            email: "",
            code:"",
            num:"",
            uuid:'',
            // 校验手机号码
            telRules: [{
                required: true,
                message: '手机号码不能为空',
                trigger: 'onBlur'
            }, {
                // 自定义校验规则
                validator: value => {
                    return /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/
                        .test(value)
                },
                message: '请输入正确格式的手机号码',
                trigger: 'onBlur'
            }],
             pwdRules: [{
                required: true,
                message: '密码不能为空',
                trigger: 'onBlur'
            }, {
                // 自定义校验规则
                validator: value => {
                    return /^[a-z0-9]{6,11}$/
                        .test(value)
                },
                message: '请输入正确格式的密码/小写字母与数字组成6-11位',
                trigger: 'onBlur'
            }],
             emailRules: [{
                required: true,
                message: '邮箱不能为空',
                trigger: 'onBlur'
            }, {
                // 自定义校验规则
                validator: value => {
                    return /^[1-9][0-9]{7,10}@qq.com$/
                        .test(value)
                },
                message: '请输入正确格式的QQ邮箱',
                trigger: 'onBlur'
            }],


        }
    },
    created() {
        // this.getipAdress()
        // this.getUserIp()
        this.uuid = uuidv4();
        let url = window.location.href;
        if(url.indexOf('?/code')>0){
            this.inviteCode = url.split('?/code=')[1].toString().split('#')[0]
        }
        this.createTime = this.getNowTime()
        let aaa = sessionStorage.getItem("userIp")

        let bbb = localStorage.getItem('IpTwo')
        if((aaa && aaa!='') || (bbb && bbb!='')){
            this.userIp = bbb || aaa
            this.getNumByIP()
        }

        

        // this.userIp = aaa
     
        // this.count()
        // this.getNumByIP()

    },
  
    computed:{
        // ...mapMutations(["changeFlag","changeFlag1"]),
    },
    methods: {
        getipAdress(){
            try{

                ipAdress().then((res)=>{

                    if(res.status == 200){
                        // console.log(res.data.data.split(":")[3]);
                        this.userIp = res.data.data.split(":")[3]
                        setTimeout(()=>{
                            this.getNumByIP()
                        },200)
                    }
            
                })
                         }catch(err){
                alert(6)
            }
           alert(3)
        },
        // ip获取注册
      async getNumByIP(){
            let ip = this.userIp
            if(ip!=''){
                 let data = {
                    ip:ip
                }
                await getNumByIP(data).then((res)=>{
                        // console.log(res);
                        if(res.status == 200){
                            this.ipNum = res.data.length
                            // console.log(this.ipNum);
                        }
                    })
            }
             
           
        },
        // 获取用户总数
       async count(){
           await num().then((res)=>{
                let aaa = res.data.data.data[0]["count(*)"]
                this.num = aaa
            })
        },

        updateCountdown() {
            var countdownElement = document.getElementById('codeBtn')
            if (this.countdown == 0) {
                this.countdown = 60
                countdownElement.textContent = '发送';
                this.disabled = false;
                window._timerList.forEach((item) => {
                            clearInterval(item);
                        })
            } else {
                this.countdown--;
                countdownElement.textContent = this.countdown + ' 秒';
                this.disabled = true;
            }
        },

        // 验证码
        sendCode() { 
            if (this.username == '' || this.email == '') { 
                Toast({
                    message: '账号密码及邮箱不能为空',
                    position: 'bottom',
                    });
                return
            }
            this.disabled = true
            window._timerList = []
            var timer  = setInterval(this.updateCountdown, 1000);
            window._timerList.push(timer)
            let data = {
                email: this.email,
                username: this.username,
                createtime: this._getNowTime(),
                id: uuidv4(),
            }
            getRegCode(data).then((res) => { 
                if (res.data.code == 0) {
                Dialog.alert({
                        title: '温馨提示',
                        message: '验证码已发送至邮箱',
                        }).then(() => {
                        // on close
                        });
                } else { 
                    Dialog.alert({
                        title: '温馨提示',
                        message: '发送失败',
                        }).then(() => {
                        // on close
                        });
                }
            })
        },
         //随机四位验证码
         filterStr(){
        //数字+字母
            var str = 'QWERTYUIOPASDFGHJKLZXCVBNMabcdefghjklmnpqrstuvwxyz';
            var res_ = '';
            for (let i = 0; i < 4; i++) {
                //随机产生字符串的下标
                var n = parseInt(Math.random() * str.length)
                res_ += str[n]
            }
                return res_
            },
        // 注册
         onSubmit() {
                let data = {
                username:this.username,
                password:this.password1,
                passwordTwo:this.password2,
                email:this.email,
                num:this.uuid,
                ip:this.userIp,
                createTime:this.createTime,
                inviteCode: this.inviteCode || '',
                beInviteCode: this.filterStr(),
                code:this.code
            }
             // if(data.username=="" || data.password=="" || data.passwordTwo=="" || data.email=="" || data.code==""){
            if(data.username=="" || data.password=="" || data.passwordTwo=="" || data.email==""){
                Toast({
                    message: '账号密码邮箱不能为空',
                    position: 'bottom',
                    });
            } else if (data.password.length<6) {
                Toast({
                    message: '密码长度不低于6位',
                    position: 'bottom',
                    });
            } else if(data.password != data.passwordTwo){
                    Toast({
                    message: '两次密码输入不一致',
                    position: 'bottom',
                    });
            }else if(this.ipNum>=2){
                    Toast({
                    message: '请勿批量注册谢谢',
                    position: 'bottom',
                    });
            }else{
               if(this.testPhone(data.username) && this.testEmail(data.email)){
                    register(data).then((res)=>{
                        if(res.data.code == 303){
                            Toast({
                                message: res.data.data.msg,
                                position: 'bottom',
                                });
                        }else if(res.data.code == 308){
                            Toast({
                                message: '注册失败,请刷新或更换浏览器',
                                position: 'bottom',
                                });
                        }else if(res.data.code == 200){
                            Toast({
                                message: '注册成功',
                                position: 'bottom',
                                });
                                let time = this.$getNYRSFM()
                                let uuid = uuidv4()
                         
                                this.$operLog('注册成功',uuid,this.username,'','',window.location.host,time)
                           
                                // if(this.inviteCode!="" && this.inviteCode!=null){
                                //     let data = {
                                //         code:this.inviteCode
                                //     }
                                //     getUserByCode(data).then((res)=>{
                                //         console.log(res,123466);
                                //         if(res.status == 200 ){
                                //             let topname = res.data.data[0].username
                                //             let data_ = {
                                //                 username:topname
                                //             }
                                //             addJF(data_).then((res)=>{
                                //                 console.log(res);
                                //             })
                                //         }
                                //     })
                                // }
                            setTimeout(() => { 
                                this.$router.push({
                                    path:"./login"
                                })
                            },600)

                        }
                    })
                }else{
                    Toast({
                    message: '请输入正确格式手机号或邮箱',
                    position: 'bottom',
                    });
                }
       
         
        
            }
            },
        testPhone(val){
            const data = /^[1][3,4,5,7,8][0-9]{9}$/;
            if(!data.test(val)){
                return false
            }else{
                return true
            }
        },
        testEmail(val){
            const data = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+$/
            if(!data.test(val)){
                return false
            }else{
                return true
            }
        },
        zhaohui(){

        },
        onClickLeft(){
            this.$router.back()
        },
      

//获取当前时间
getNowTime() {
    var date = new Date();
    //年 getFullYear()：四位数字返回年份
    var year = date.getFullYear();  //getFullYear()代替getYear()
    //月 getMonth()：0 ~ 11
    var month = date.getMonth() + 1;
    //日 getDate()：(1 ~ 31)
    var day = date.getDate();
    // //时 getHours()：(0 ~ 23)
    // var hour = date.getHours();
    // //分 getMinutes()： (0 ~ 59)
    // var minute = date.getMinutes();
    // //秒 getSeconds()：(0 ~ 59)
    // var second = date.getSeconds();

    // var time = '当前时间是：' + year + '-' + this.addZero(month) + '-' + this.addZero(day) + ' ' + this.addZero(hour) + ':' + this.addZero(minute) + ':' + this.addZero(second);
    var time = year + '-' + this.addZero(month) + '-' + this.addZero(day)
    return time;
        },
//获取当前时间
_getNowTime() {
    var date = new Date();
    //年 getFullYear()：四位数字返回年份
    var year = date.getFullYear();  //getFullYear()代替getYear()
    //月 getMonth()：0 ~ 11
    var month = date.getMonth() + 1;
    //日 getDate()：(1 ~ 31)
    var day = date.getDate();
    //时 getHours()：(0 ~ 23)
    var hour = date.getHours();
    //分 getMinutes()： (0 ~ 59)
    var minute = date.getMinutes();
    //秒 getSeconds()：(0 ~ 59)
    var second = date.getSeconds();

    // var time = '当前时间是：' + year + '-' + this.addZero(month) + '-' + this.addZero(day) + ' ' + this.addZero(hour) + ':' + this.addZero(minute) + ':' + this.addZero(second);
    var time = year + '-' + this.addZero(month) + '-' + this.addZero(day)+ ' ' + this.addZero(hour) + ':' + this.addZero(minute) + ':' + this.addZero(second);
    return time;
},
getUserIp(){
    var url = 'http://myip.ipip.net'
    axios.get(url).then((res)=>{
        console.log(res,'77');
    })
},


//小于10的拼接上0字符串
addZero(s) {
    return s < 10 ? ('0' + s) : s;
},
//获取客户端IP地址



    },
async mounted() {
    // this.changeFlag();
    this.$store.commit('changeFlag')
  },
  destroyed() {
      // this.changeFlag1();
      this.$store.commit('changeFlag1')
  },
}
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}
#container {
  width: 100%;
  background-color: #f5f5f5;
  height: 100%;
  padding: 0 10px;
  box-sizing: border-box;
}
#container .header{
    height: 60px;
}
/deep/.van-nav-bar__content{
    height: 60px !important;
}
/deep/.van-nav-bar__content{
    background-color: #f5f5f5;
} 
 /deep/.van-nav-bar__text{
   

    color: #ec4899 !important;
}
/deep/.van-nav-bar .van-icon{
    color: #ec4899 !important;
}
#container .logo{
    width: 100%;
    height: 150px;
    margin-top: 0px;
    /* background-color: #fff; */
    border-radius: 10px;
    position: relative;
}
#container .logo .content{
    width: 120px;
    height: 120px;
    position: absolute;
    left: 35%;
    top: 10%;
    border-radius: 50%;
    background-color: #fff;
}
#container .logo .content img{
    width: 100%;
    border-radius: inherit;
    background-position: 50px 50px;
}
#container .logo img{
    margin-top: 0px;
}
#container .body{
    width: 86%;
    height: 310px;
    margin: 0  7%;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    /* filter: blur(100px); */
    padding-top: 20px;
}
#container .body .line{
    width: 90%;
    height: 50px;
    margin: 10px 0;
}
#container .body .line .left{
    width: 25%;
    float: left;
    height: 100%;
    position: relative;
}
#container .body .line .left .iii{
    position: absolute;
    top: 5px;

}
#container .body .line .right{
    width: 75%;
    float: left;
    height: 100%;
    position: relative;
}
#container .body .line .right .input_{
    font-size: 16px;
    border: none;
    height: 35px;
    position: absolute;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}
#container .login{
    width: 100%;
    height: 150px;
    background-color: #fff;
    margin-top: 10px;
}
#container .login .yhm{
    height: 50px;
    line-height: 50px;
}
#container .login .yhm span{
    font-size: 14px;
}
#container .login .yhm  input{
    width: 60%;
    height: 30px;
    border: none;
    background-color: #fff;
}

#container .login .mm span{
    font-size: 14px;
}
/deep/.van-form{
    height: 100%;
}
/deep/.van-cell{
    height: 75px;
}
/deep/.van-cell span{
    font-size: 18px;
    font-weight: 700;
    color: #ec4899;
}
/deep/.van-field__label{
    text-align: right;
    margin-top: 20px;
}
/deep/.van-cell__value{
    margin-top: 20px;
}
</style>