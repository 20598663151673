import { LOGIN,INIT_USER } from './mutations-type'

import { Toast } from 'vant';

import { clearCookie } from '../../utils/cookie';

export default {
    state: {
        // 登陆状态
        loginStatus: false,
        // token
        token: null,
        // 用户信息
        userInfo: {

        },
        // 导航栏
        flag: true,
        code:localStorage.getItem('code'),
    },
    getters: {

    },
    mutations: {
        // 设置
        [LOGIN](state, user) { 
            state.loginStatus = true
            state.token = user
            state.userInfo = user.data.data[0]
            // state.code = user.data.data[0].code
            state.flag = true
            // console.log(state,user,state.userInfo);
            
            // 持久化存储
            localStorage.setItem("teaUserInfo",JSON.stringify(user))
        },
        // 读取
        [INIT_USER](state) {
          let userInfo =  JSON.parse(localStorage.getItem("teaUserInfo"))
            if (userInfo) { 
                state.loginStatus = true
                state.token = userInfo
                state.userInfo = userInfo.data.data[0]
            }
        },
        // code
        changeCode(state,code) { 
            state.code = code
        },
        // 退出登录
        loginOut(state) { 
            localStorage.removeItem("code")
            state.loginStatus = false
            state.code = ""
            state.token = null
            state.userInfo = {}
            clearCookie('token')
            localStorage.removeItem("teaUserInfo")

            Toast({
                message: '退出成功',
                position: 'bottom',
                });
        },
        // 导航栏
        changeFlag(state){
            state.flag = false;
        },
        changeFlag1(state) { 
            state.flag = true;

        },
        // 修改积分
        updataJF(state,score) { 
            state.userInfo.score = score
        },
        updataCode(state,code) { 
            state.code = code
            localStorage.setItem("code",code)
        },
     
    },
    actions: {
 
    }

}