import request from "../utils/request"

// 登录
export function login(data) {
    return request({
      url: '/users/login',
      method: 'post',
      data:data
    })
}
  
// 注册
export function register(data) {
    return request({
      url: '/users/register',
      method: 'post',
      data:data
    })
}
// 条数
export function num() {
    return request({
      url: '/users/count',
      method: 'get',
    })
}
  
// 修改登录状态
export function uploginState(data) { 
  return request({
    url: '/users/uploginState',
    method: 'post',
    data:data
  })
}

// 查询登录状态
export function getloginState(data) { 
  return request({
    url: '/users/getloginState',
    method: 'post',
    data:data
  })
}
// 查询卡密是否存在
export function getKami(data) { 
  return request({
    url: '/users/findkm',
    method: 'post',
    data:data
  })
}
// 升级vip
export function upUsertype(data) { 
  return request({
    url: '/users/vip',
    method: 'post',
    data:data
  })
}
// 修改卡密使用状态
export function upkmState(data) { 
  return request({
    url: '/users/upkmState',
    method: 'post',
    data:data
  })
}

// 查询账户积分
export function getJFXX(data) { 
  return request({
    url: '/users/getJF',
    method: 'post',
    data:data
  })
}

// 扣除积分
export function payTZ(data) { 
  return request({
    url: '/users/payTZ',
    method: 'post',
    data:data
  })
}

//用户购买帖子存入账户
export function insetIds(data) { 
  return request({
    url: '/users/insetIds',
    method: 'post',
    data:data
  })
}
//查询用户购买帖子
export function getTZ(data) { 
  return request({
    url: '/users/getTZ',
    method: 'post',
    data:data
  })
}

// 查询用户购买帖子信息
export function getTzInfo(data) { 
  return request({
    url: '/users/getTzInfo',
    method: 'post',
    data:data
  })
}
// 新增用户邀请码
export function insertCode(data) { 
  return request({
    url: '/users/code',
    method: 'post',
    data:data
  })
}

export function getCode(data) { 
  return request({
    url: '/users/getCode',
    method: 'post',
    data:data
  })
}

export function getAllCode() {
  return request({
    url: '/users/getAllCode',
    method: 'get',
  })
}
export function getUserByCode(data) {
  return request({
    url: '/users/getUserByCode',
    method: 'post',
    data:data
  })
}
export function addJF(data) { 
  return request({
    url: '/users/addJF',
    method: 'post',
    data:data
  })
}

export function ipAdress() {
  return request({
    url: '/users/ipAdress',
    method: 'get',
  })
}

export function resetState(data) {
  return request({
    url: '/users/resetState',
    method: 'post',
    data:data
  })
}

export function upbyhand(data) {
  return request({
    url: '/users/upbyhand',
    method: 'post',
    data:data
  })
}

export function searchUser(data) {
  return request({
    url: '/users/searchUser',
    method: 'post',
    data:data
  })
}

// 查询今日注册
export function getTodayRegister(data) {
  return request({
    url: '/users/getTodayRegister',
    method: 'post',
    data:data
  })
}
export function getTodayVIP(data) {
  return request({
    url: '/users/getTodayVIP',
    method: 'post',
    data:data
  })
}

// 查询昨日注册
export function getYesterdayRegister() { 
  return request({
    url: "/users/getYesterdayRegister",
    method:"get"
  })
}

export function getYesterdayVIP() {
  return request({
    url: '/users/getYesterdayVIP',
    method: 'get',
  })
}

// 积分修改
export function updateScore(data) {
  return request({
    url: '/users/updateScore',
    method: 'post',
    data:data
  })
}

export function getUserBuyNum(data) {
  return request({
    url: '/users/getUserBuyNum',
    method: 'post',
    data:data
  })
}

// export function operlog(data) {
//   return request({
//     url: '/users/operlog',
//     method: 'post',
//     data:data
//   })
// }




export function getNYRSFM() { 
    // 创建一个 Date 对象，表示当前时间
    let now = new Date();

    // 获取年份
    let year = now.getFullYear();

    // 获取月份（注意：月份从 0 开始，因此需要加 1）
    let month = now.getMonth() + 1;

    // 获取日期
    let date = now.getDate();

    // 获取小时
    let hours = now.getHours();

    // 获取分钟
    let minutes = now.getMinutes();

    // 获取秒数
    let seconds = now.getSeconds();

    // 将单个数字的月份、日期、小时、分钟、秒数补零为两位数
    month = month < 10 ? '0' + month : month;
    date = date < 10 ? '0' + date : date;
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;

    // 构造返回的时间字符串
  let currentTime = `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`;
  return currentTime
}


export function getShareImg(data) {
  return request({
    url: '/share/shareImg',
    method: 'post',
    data:data
  })
}

export function insertUploads(data) {
  return request({
    headers: {
      'Content-Type': "multipart/form-data;boundary=----WebKitFormBoundaryCHhHs7kAWgSIDMQY"
    },
    url: '/statistics/uploads',
    method: 'post',
    data:data
  })
}

export function insertFk(data) {
  return request({
    url: '/statistics/insetFeedback',
    method: 'post',
    data:data
  })
}

export function getFk(data) {
  return request({
    url: '/statistics/getUserFk',
    method: 'post',
    data:data
  })
}

export function editUserFk(data) {
  return request({
    url: '/statistics/editUserFk',
    method: 'post',
    data:data
  })
}


export function getRegCode(data) {
  return request({
    url: '/users/getRegisterCode',
    method: 'post',
    data:data
  })
}
