<template>
    <div class="container el-main">
   <div style="height:60px">
         <div class="header">
            <van-icon name="arrow-left" size="24" style="" color="#ec4899" @click="goback" />
            <van-search v-model="value" placeholder="请输入搜索关键词" clear/>
            <span @click="search">搜索</span>
        </div>
   </div>
        <div class="content">
                    <van-pull-refresh
          v-model="refreshing" @refresh="onRefresh"
          success-text="刷新成功"
          loading-text="刷新中"
        >
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text="要不您来加一条"
            :immediate-check="false"
            @load="onLoad"
          >
                    <div
          class="list"
          v-for="item in list"
          :key="item.id"
          @click="detail(item)"
        >
          <div :class="item.picture==''?'left2':'left'">
            <ul style="text-align: left; heigth: 100%">
              <li style="font-size: 16px; margin-bottom: 3px">
                {{ item.title }}
              </li>
              <div style="color: #a3a3a3; line-height: 30px">
                <van-icon
                  size="12"
                  style="margin-right: 5px"
                  name="clock-o"
                /><span style="font-size: 12px; margin-right: 5px">{{
                  filterTime(item.publishedAt)
                }}</span>
                <van-icon
                  size="12"
                  style="margin-right: 5px"
                  name="eye-o"
                /><span style="font-size: 12px">{{ item.viewCount }}</span>
              </div>
              <div
                style="font-size:14px;width:100%;height:55px; color:#525252;
                            display:-webkit-box;
                                overflow:hidden;
                                text-overflow:ellipsis;
                                -webkit-line-clamp:4;
                                -webkit-box-orient:vertical;"
              >
                {{ item.desc }}
              </div>
              <div
                class="bottom"
                style="height: 30px; line-height: 30px; margin-top: 18px"
              >
                <span
                  ><van-icon
                    name="guide-o"
                    size="18"
                    color="#ec4899"
                    style="margin-right: 15px" /></span
                ><span class="city" style="font-size: 14px;color:'#ec4899'">{{filterCity(item.cityCode)}}</span>
              </div>
            </ul>
          </div>
            <div class="right" v-if="item.coverPicture == ''">
            <img
              :src="baseUrl+item.picture.split(',')[0]"
              alt=""
              @error.once="moveErrorImg"
            />
          </div>
          <div class="right" v-else>
            <img
              :src="baseUrl+item.coverPicture"
              alt=""
              @error.once="moveErrorImg"
            />
          </div>
        
        </div>
        </van-list>
      </van-pull-refresh>
        </div>
    </div>
</template>

<script>
import { Toast } from 'vant';
import moment from "moment";
import { getDataBytitle,getNumBytitle } from "../../model/index";
// import { mapMutations } from "vuex"

export default {
    data() {
        return {
            value:"",
            list:[],
          // baseUrl: "http://38.54.111.82/info/picture/",
          // baseUrl: "http://38.54.111.182/info/picture/",
          baseUrl: "http://178.157.60.44/info/picture/",
            loading: false,
            finished: false,
            refreshing: false,
            pageNumber:1,
            total:"",
            scroll:0,

        }
    },
    computed:{
        // ...mapMutations(["changeFlag","changeFlag1"]),

    },
      watch:{
    'value'(val){
      if(val){
        console.log(122);
      }else{
        this.pageNumber = 1
        this.list = []
        this.finished = false
    
      }
      console.log(val);
    }
  },

  methods: {
          // 格式化地区
          filterCity(val){
      
      let cityCode = val
    if(cityCode != null || cityCode !=""){
      let val = cityCode.toString()
      let twoCode = val.slice(0,2)
      let arr = this.province.filter((item)=>{
          return item.cityCode.toString().slice(0,2) == twoCode
      })
      let brr = arr[0].city.filter((item)=>{
          return item.cityCode == cityCode
      })
      let pName = arr[0].cityName
      let cName = brr[0].cityName
      // let pName
      // let cName
      // if(arr[0]!=null && arr[0]!=''){
      // pName = arr[0].cityName

      // }
      // if(brr[0]!=null && brr[0]!=''){
      // cName = brr[0].cityName

      // }
      // console.log(arr);
      // console.log(brr);
      // console.log(twoCode);
      // console.log(val);
      // console.log(pName+cName);
      return pName+'-'+cName
    }else{
      return '天上人间'
    }
    
  },
      goback(){
        this.$router.back()
      },
      search(){
        // let data = {
        //     title:this.value
        // }
        // getDataBytitle(data).then((res)=>{
        //     if(res.status == 200){
        //         this.list = res.data.data
        //     }
        // })
        // this.onLoad()
        this.pageNumber = 1
        this.list=[]
        this.getNUM()
        this.getListdata()
      },
      //num
      getNUM(){
        let data = {
          title:this.value
        }
         if(data.title==""){
          Toast({
                    message: '搜索内容不能为空',
                    position: 'top',
             });
       }else{
          getNumBytitle(data).then((res)=>{
            if(res.data.code==0){
              console.log(res.data.length);
            }
            this.total = res.data.length
            
        })
       }
   
      },
      // 请求数据
    getListdata(){
      setTimeout(()=>{
        let data = {
          pageNumber : this.pageNumber++,
          title:this.value
       }
       if(data.title==""){
          Toast({
                    message: '搜索内容不能为空',
                    position: 'top',
             });
       }else{
             getDataBytitle(data).then((res)=>{
            if (this.refreshing) {
            this.list = [];
            this.refreshing = false;
        }
        console.log(res);
        // setTimeout(()=>{
          this.list = this.list.concat(res.data.data)
          console.log(this.list,66);
          console.log(this.list.length, this.total);
          if (this.list.length >= this.total) {
                this.finished = true;
                return;
              }
              this.loading = false;
        // },200)
        
       })
     }
  
      },1500)
    },
    // 加载
     onLoad(){
      this.getListdata()
    },
    // 下拉
     onRefresh() {
      // 清空列表数据
      this.finished = false
      this.pageNumber = 1
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.getListdata()
    },
    //   详情
      detail(val){
            console.log(val,88);
            this.$router.push({
            path: "/detail",
            query: { id: val.id },
        });
    },
    //处理破图
    moveErrorImg(event) {
        event.currentTarget.src = require("../../assets/images/err.jpg");
        event.currentTarget.style.width = "auto";
        return true;
      },
    // 格式化时间
    filterTime(value) {
      if (value != null || value != "") {
        let val = value;
        let momentObj = moment(val * 1).format("YYYY-MM-DD");
        return momentObj;
      } else {
        return "";
      }
    },
    },
  async mounted() {
    // this.changeFlag();
    this.$store.commit('changeFlag')
  },
  destroyed() {
    // this.changeFlag1();
    this.$store.commit('changeFlag1')
  },
}
</script>

<style scoped>
*{
    margin: 0;
    padding: 0;
}
li{
    list-style: none;
}
.container{
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;

}
.container .header{
    width: 100%;
    height: 60px;
    /* color: #ec4899; */
    display: flex;
    align-items: center;
    justify-content: space-around;
    /* padding-left: 3%; */
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 9999;
    overflow: hidden;
}
.container .header span{
    font-size: 16px;
    color: #ec4899;
    margin-right: 15px;
}
/deep/.van-search{
    height: 100%;
    width: 70%;
    background-color: #fff;
}
/deep/.van-search__content {
    border:  1px solid #ec4899;
    border-radius: 10px;
}
/deep/.van-cell{
    padding-left: 20px;
    color: #ec4899;
}
.container .content{
    height: calc(100% - 60px);
    width: 100%;
    padding: 0px 10px;
    box-sizing: border-box;
    overflow-y: scroll;
}
.content  .list {
  box-sizing: border-box;
  width: 100%;
  height: 176px;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  margin-bottom: 10px;
}
.content  .list .left {
  width: 65%;
  height: 100%;
}
.content  .list .left1 {
  width: 100%;
  height: 100%;
}
.content  .list .right {
  width: 35%;
  height: 100%;
}
.content  .list .right img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.content  .list .left .tit {
  font-size: 16px;
  height: 20px;
  margin-left: -65px;
}
.content  .list .left .tip {
  height: 15px;
  /* font-size: 12px; */
}
</style>