<template>
  <content-loader
      :width="414"
      :height="700"
      :speed="1"
      primaryColor="#f1f1f1"
      secondaryColor="#ecebeb"
      v-show="visable"
    >
      <rect x="6" y="12" rx="5" ry="5" width="100" height="50" />
      
      <rect x="7" y="70" rx="0" ry="0" width="400" height="270" />
     
      <rect x="123" y="12" rx="5" ry="5" width="150" height="50" />
      <rect x="6" y="350" rx="5" ry="5" width="250" height="50" />
      <rect x="6" y="405" rx="5" ry="5" width="200" height="20" />
      <rect x="6" y="430" rx="0" ry="0" width="400" height="170" />
      <rect x="6" y="605" rx="0" ry="0" width="400" height="120" />




      
    </content-loader>

</template>

<script>
import { ContentLoader } from 'vue-content-loader'
export default {
  components: {
    ContentLoader
  },
  data() {
    return {
        visable:true
    }
  },
  methods: {
    changeVisable(){
        this.visable = false
    }
  },

}
</script>

<style>

</style>