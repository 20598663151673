import request from "../utils/request"

//最新
export function getZXList(data) {
    return request({
      url: '/ylyf/getZXlist',
      method: 'post',
      data:data
    })
}
//本周
export function getWeeklist(data) {
    return request({
      url: '/ylyf/getWeeklist',
      method: 'post',
      data:data
    })
}
//本月
export function getNowMonthlist(data) {
    return request({
      url: '/ylyf/getNowMonthlist',
      method: 'post',
      data:data
    })
}
//上月
export function getUpMonthList(data) {
    return request({
      url: '/ylyf/getUpMonthList',
      method: 'post',
      data:data
    })
}
  
//最新-地名
export function getZXlistByCid(data) {
  return request({
    url: '/ylyf/getZXlistByCid',
    method: 'post',
    data:data
  })
}
// 本周-地名
export function getWeeklistByCid(data) {
  return request({
    url: '/ylyf/getWeeklistByCid',
    method: 'post',
    data:data
  })
}
// 本月-地名
export function getNowMonthByCid(data) {
  return request({
    url: '/ylyf/getNowMonthByCid',
    method: 'post',
    data:data
  })
}
// 上月-地名
export function getUpMonthByCid(data) {
  return request({
    url: '/ylyf/getUpMonthByCid',
    method: 'post',
    data:data
  })
}

//根据id查询详情
export function getDeatilById(data) {
  return request({
    url: '/ylyf/getDeatilById',
    method: 'post',
    data:data
  })
}
