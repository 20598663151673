<template>
    <div id="container">
  <div class="header" >
        <van-nav-bar
        title=""
        left-text="返回"
        right-text=""
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
        />
    </div>
    <div class="logo" >
        <!-- <img src="../../assets/images/title.png" alt=""> -->
            <img src="../../assets/images/uugai.com-1032126-16719525801216.png" alt="">
    </div>
    <div class="content">
            <div class="font">
              <!-- <span style="font-weight:700;color:#000">第一步,查看授权代理类型</span> -->
              <table align="center" border-collapse="collapse">
                  <tr>
                    <th align="center" valign="middle">类型</th>
                    <th align="center" valign="middle">价格</th>
                    <th align="center" valign="middle">会员授权</th>
                    <th align="center" valign="middle">有效期</th>
                  </tr>
                  <tr>
                    <td align="center" valign="middle">季度代理</td>
                    <td align="center" valign="middle">详谈</td>
                    <td align="center" valign="middle">是</td>
                    <!-- <td align="center" valign="middle">90天</td> -->
                    <td align="center" valign="middle">每月授权66个</td>

                  </tr>
                  <tr>
                    <td align="center" valign="middle">永久代理</td>
                    <td align="center" valign="middle">详谈</td>
                    <td align="center" valign="middle">是</td>
                    <td align="center" valign="middle">无上限授权</td>
                  </tr>
                  <tr>
                    <td align="center" valign="middle">整站代理</td>
                    <td align="center" valign="middle">详谈</td>
                    <td align="center" valign="middle">是</td>
                    <td align="center" valign="middle">独立收益/无上限</td>
                  </tr>
              </table>
        <!-- <span style="font-weight:700;color:#000">第二步,查看授权代理条件</span><br>
         -当你对联系对方有所顾虑的时候，请您尝试百度一下这个联系方式，进行多方的比对。<br> -->
    </div>
    <div class="lxfs" @click="tel">
      诚挚详谈 旺旺号:7161464
    </div>
    </div>
    </div>
</template>

<script>
import { mapState } from "vuex"
export default {
    data() {
        return {
            
        }
    },
    computed:{
        // ...mapMutations(["changeFlag","changeFlag1"]),
        ...mapState({
            loginStatus : state=>state.user.loginStatus,
            userInfo : state=>state.user.userInfo
        }),
        loginStatus(){
          return this.$store.state.user.loginStatus
        },
        userInfo(){
          return this.$store.state.user.userInfo
        },
        
    },
    methods: {
      tel(){
          window.location.href="http://www.quyutech.cn/desktop"
        },
        onClickLeft(){
          
            // this.$router.back()
            window.history.back(-1)
         
        },
        onClickRight(){
        },
    },



    async mounted() {
    // this.ces = true
      // this.ces = true
      // this.changeFlag();
      this.$store.commit('changeFlag')
  },
  destroyed() {
    // this.changeFlag1();
    this.$store.commit('changeFlag1')
  },

}
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}
#container{
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;

}
#container .header{
    width: 100%;
    height: 60px;
    /* color: #ec4899; */
}
#container .logo{
    position: absolute;
    top: 0px;
    /* left: 34%; */
    left: calc(50% - 30px);
    z-index: 99;
    width: 60px;
    height: 60px;
    border-radius: 50%;

}
#container .logo img{
     width: 100%;
     height: 100%;
    border-radius: inherit;
    background-position: 50px 50px;
}
#container .content{
    width: 100%;
    height: calc(100% - 60px);
    overflow-y: scroll;
    padding: 0 10px;
    box-sizing: border-box;
}


#container .content .font{
  text-align: left;
  font-size: 14px;
  line-height: 24px;
}

table,table tr th,table tr td{
  border: 1px solid #ccc;
}
table{
  border-collapse: collapse;
  width: 100%;
}
 /deep/.van-nav-bar__text{
   

    color: #ec4899 !important;
}
/deep/.van-nav-bar .van-icon{
    color: #ec4899 !important;
}
/deep/.van-nav-bar__content{
    height: 60px !important;
}
#container .content .lxfs{
  width: 100%;
  height: 45px;
  font-size: 20px;
  margin-top: 50px;
  line-height: 45px;
  font-weight: 700;
  border-radius: 15px;
  background-image: linear-gradient( 135deg, #FFF3B0 10%, #CA26FF 100%) !important;
}
</style>