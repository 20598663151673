<template>
    <div id="container">
        <div class="header">
                <van-nav-bar
                title=""
                left-text="返回"
                right-text=""
                left-arrow
                @click-left="onClickLeft"
                @click-right="onClickRight"
                />
            </div>
            <div class="logo">
                <!-- <img src="../../assets/images/title.png" alt=""> -->
                    <img src="../../assets/images/uugai.com-1032126-16719525801216.png" alt="">
            </div>
            <!--  -->
            <div class="content">
                <div class="info">
                    <div class="logins">
                        <div class="title">今日注册</div>
                        <div class="num">
                            {{TODregisterNum}}
                        </div>
                    </div>
                    <div class="zhuce">
                        <div class="title">今日会员</div>
                        <div class="num">
                            {{TODvipNum}}
                        </div>
                    </div>

                  
                </div>


                <div class="info">
                    <div class="logins">
                        <div class="title">昨日注册</div>
                        <div class="num">
                            {{YESregisterNum}}
                        </div>
                    </div>
                    <div class="zhuce">
                        <div class="title">昨日会员</div>
                        <div class="num">
                            {{YESvipNum}}
                        </div>
                    </div>

                 
                </div>
                <div>
                    <button @click="clickTest('https://r1.img7.xyz/info/picture/231127/fd82cbf9-ac37-48d9-a1ed-396e11810c7e_t.jpg')">测试</button>
                </div>
            </div>
    </div>
</template>

<script>
// import { mapMutations } from "vuex"
import { resetState,upbyhand,searchUser,getTodayRegister,getTodayVIP,getYesterdayRegister,getYesterdayVIP } from "../../model/user"
import { Toast } from 'vant';
// import axios from 'axios'



export default {
    data() {
        return {
            username:'',
            phone:"",
            FileName: "JsToJsonFile",
            ShowListData: [],
            infoEnd:[],
            createTime:'',
            TODregisterNum:0,
            YESregisterNum:0,
            TODvipNum:0,
            YESvipNum:0,
        }
    },
    computed:{
        //  ...mapMutations(["changeFlag","changeFlag1"]),
    },
    created() {

        // this.getTreeData()

        // this.getInfo()
        this.createTime = this.getNowTime()
        this.getData()
    },
    methods: {
      async getData(){
          let data = {
          time:this.createTime
          }
          await  getTodayRegister(data).then((res)=>{
               this.TODregisterNum =  res.data.data.length
            })
          await  getTodayVIP(data).then((res)=>{
                this.vipNum =  res.data.data.length
            })
          await getYesterdayRegister().then((res)=>{
                this.YESregisterNum =  res.data.data.length
          })
          await getYesterdayVIP().then((res)=>{
                this.YESvipNum =  res.data.data.length
          })
        }, 
        //获取当前时间
        getNowTime() {
            var date = new Date();
            //年 getFullYear()：四位数字返回年份
            var year = date.getFullYear();  //getFullYear()代替getYear()
            //月 getMonth()：0 ~ 11
            var month = date.getMonth() + 1;
            //日 getDate()：(1 ~ 31)
            var day = date.getDate();
            // //时 getHours()：(0 ~ 23)
            // var hour = date.getHours();
            // //分 getMinutes()： (0 ~ 59)
            // var minute = date.getMinutes();
            // //秒 getSeconds()：(0 ~ 59)
            // var second = date.getSeconds();

            // var time = '当前时间是：' + year + '-' + this.addZero(month) + '-' + this.addZero(day) + ' ' + this.addZero(hour) + ':' + this.addZero(minute) + ':' + this.addZero(second);
            var time = year + '-' + this.addZero(month) + '-' + this.addZero(day)
            return time;
        },
        //小于10的拼接上0字符串
        addZero(s) {
            return s < 10 ? ('0' + s) : s;
        },







       
       
       

        search(){
            let data = {
                username : this.username
            }
            searchUser(data).then((res)=>{
                console.log(res,777);
                if(res.data.data.length && res.data.data.length == 1){
                     Toast.success('查询成功')
                }else{
                    Toast.fail('查询失败')
                }
                
            })
        },
        hysj(){
            let data = {
                username : this.phone
            }
            upbyhand(data).then((res)=>{
                console.log(res);
                Toast.success('升级成功')
            })
        },
        reset(){
          let data = {
            loginState : 1
          }
          resetState(data).then((res)=>{
            console.log(res);
            Toast.success('修改成功')
          })
        },
        clean(){
            this.phone = ""
            this.username = ""
        },
        onClickLeft(){
            window.history.back(-1)
        },
        onClickRight(){

        },
        // 将下载下来的二进制大对象数据转换成base64，然后展示在页面上
 handleBlob(blob) {
    let reader = new FileReader();
    reader.onload = function (evt) {
        let img = document.createElement('img');
        img.src = evt.target.result;
        console.log(evt,'sdsdsd');
        // document.getElementById('container').appendChild(img)
    };
    reader.readAsDataURL(blob);
},
 
// const imgSrc = "https://img-blog.csdnimg.cn/5ff039c8a8cd47c98e26d694854e8962.jpeg";
 
 
    fetchImage(url) {
        return fetch(url, {
            headers: {
                // "Referer": "", // 这里设置无效
            },
            method: "GET",
            referrer: "", // 将referer置空
            // referrerPolicy: 'no-referrer', 
        }).then(response => response.blob());
    },
    clickTest(imgSrc){
        this.fetchImage(imgSrc).then(blob => {
            this.handleBlob(blob);
        });
    }   
 
    
    },
   async mounted() {
    // this.ces = true
      // this.ces = true
       // this.changeFlag();
       this.$store.commit('changeFlag')
  },
  destroyed() {
      // this.changeFlag1();
      this.$store.commit('changeFlag1')
  },

}
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}
#container{
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;

}
#container .header{
    width: 100%;
    height: 60px;
    /* color: #ec4899; */
}
#container .logo{
    position: absolute;
    top: 0px;
    /* left: 34%; */
    left: calc(50% - 30px);
    z-index: 99;
    width: 60px;
    height: 60px;
    border-radius: 50%;

}
#container .logo img{
     width: 100%;
     height: 100%;
    border-radius: inherit;
    background-position: 50px 50px;
}
 /deep/.van-nav-bar__text{
   

    color: #ec4899 !important;
}
/deep/.van-nav-bar .van-icon{
    color: #ec4899 !important;
}
/deep/.van-nav-bar__content{
    height: 60px !important;
}
.content .swiper{
    width: 100%;
    height: 250px;
}
/deep/.van-swipe{
    width: 100%;
    height: 250px;
    background-color: #f5f5f5;
}
/deep/.van-swipe img{
    width: 45%;
    height: 100%;
}
#container .content{
    width: 100%;
    height: calc(100% - 60px);
    overflow-y: scroll;
    padding: 0 10px;
    box-sizing: border-box;
    overflow-x: hidden;
}
#container .content .info{
    width: 100%;
    height: 80px;
    padding: 5px;
    display: flex;
}
#container .content .info .logins{
    width: 50%;
}
#container .content .info .logins .title{
    width: 100%;
    height: 30px;
    line-height: 35px;
    font-size: 18px;
    text-align: center;
    /* color: #ec4899; */
}
#container .content .info .logins .num{
    font-size: 22px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ec4899;
}
#container .content .info .zhuce{
    width: 50%;
}
#container .content .info .zhuce .title{
    width: 100%;
    height: 30px;
    line-height: 35px;
    font-size: 18px;
    text-align: center;
    /* color: #ec4899; */
}
#container .content .info .zhuce .num{
    font-size: 22px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ec4899;
}
/* #container .content .info .title{
    width: 100%;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
    text-align: center;
} */
#container .content .info .shengji{
  width: 100%;
  height: 50px;
  font-size: 14px;
}
#container .content .info .shengji .inputvip input{
  width: 70%;
  height: 40px;
  text-indent: 20px;
  float: left;
}
#container .content .info .shengji .sjbtn li{
  margin-left: 2%;
  float: left;
  background-color: #ec4899;
  width: 25%;
  /* width: 50px; */
  height: 43px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  font-size: 16px;
}
#container .content .info li{
  margin-left: 2%;
  float: left;
  background-color: #ec4899;
  width: 25%;
  /* width: 50px; */
  height: 43px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  font-size: 16px;
}
</style>