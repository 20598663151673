import request from "../utils/request"

export function getlist() {
    return request({
      url: '/test',
      method: 'get',
    })
}
// 根据id查询
export function getDataById(data) {
  return request({
    url: '/test/id',
    method: 'post',
    data:data
  })
}

// 根据id查询
export function getDataPages(data) {
  return request({
    url: '/test/page',
    method: 'post',
    data:data
  })
}
// 根据title查询
export function getDataBytitle(data) {
  return request({
    url: '/test/title',
    method: 'post',
    data:data
  })
}
// 根据title查询数量
export function getNumBytitle(data) {
  return request({
    url: '/test/title/num',
    method: 'post',
    data:data
  })
}
// 根据cityCode查询
export function getListBycityCode(data) {
  return request({
    url: '/test/cityCode',
    method: 'post',
    data:data
  })
}


// 首页最新七天查询
export function getZXlist(data) {
  return request({
    url: '/test/getZXlist',
    method: 'post',
    data:data
  })
}
// 最新数量
export function getZXnum() {
  return request({
    url: '/test/getZXlist/num',
    method: 'get',
    
  })
}
// 首页热门七天查询
export function getRMlist(data) {
  return request({
    url: '/test/getRMlist',
    method: 'post',
    data:data
  })
}
// 热门数量
export function getRMnum() {
  return request({
    url: '/test/getZXlist/num',
    method: 'get',
    
  })
}
// 根据cityCode查询数量
export function getNumByCitycode(data) {
  return request({
    url: '/test/cityCode/num',
    method: 'post',
    data:data
  })
}

export function getNumByIP(data) {
  return request({
    url: '/test/ipAddress/num',
    method: 'post',
    data:data
  })
}

//一周数量
export function weekRMNum(data) {
  return request({
    url: '/test/weekRM/num',
    method: 'post',
    data:data
  })
}
// 一周帖子 
export function weekRM(data) {
  return request({
    url: '/test/weekRM',
    method: 'post',
    data:data
  })
}

//当月数量
export function monthRMNum(data) {
  return request({
    url: '/test/monthRM/num',
    method: 'post',
    data:data
  })
}
// 当月帖子 
export function monthRM(data) {
  return request({
    url: '/test/monthRM',
    method: 'post',
    data:data
  })
}