<template>
    <div id="container">
        <div class="header">
                <van-nav-bar
                title=""
                left-text="返回"
                right-text=""
                left-arrow
                @click-left="onClickLeft"
                @click-right="onClickRight"
                />
            </div>
            <div class="logo">
                <!-- <img src="../../assets/images/title.png" alt=""> -->
                    <img src="../../assets/images/uugai.com-1032126-16719525801216.png" alt="">
            </div>
            <!--  -->
            <div class="content">
                <div class="info">
                    <div class="title" style="color:#ec4899">
                        查询用户
                    </div>
                     <div class="shengji">
                        <div class="inputvip">
                            <input type="text" name="" v-model="username" id="" placeholder="请输入账号">
                        </div>
                        <div class="sjbtn">
                            <li @click="search">立即查询</li>
                        </div>
                    </div>
                </div>
                <div class="info info_info" v-if="searched">
                    <div class="title" style="color:#ec4899">
                        用户信息
                    </div>
                     <div class="shengji shengji_shengji">
                        <div class="row">
                            <div>用户名：{{form.username}}</div>
                            <div>密码：{{form.password}}</div>
                        </div>
                        <div class="row">
                            <div>会员：{{form.usertype}}</div>
                            <div>积分：{{form.score}}</div>
                        </div>
                        <div class="row">
                            <div>邮箱：{{form.email}}</div>
                            <div>注册时间：{{form.countdown}}</div>
                        </div>
                    </div>
                </div>
                <div class="info">
                    <div class="title" style="color:#ec4899">
                        升级会员
                    </div>
                     <div class="shengji">
                        <div class="inputvip">
                            <input type="text" name="" v-model="phone" id="" placeholder="请输入账号">
                        </div>
                        <div class="sjbtn">
                            <li @click="hysj">立即升级</li>
                        </div>
                    </div>
                </div>
                <div class="info">
                    <div class="title" style="color:#ec4899">
                        添加积分
                    </div>
                     <div class="shengji">
                        <div class="inputvip">
                            <input type="text" name="" v-model="score" id="" placeholder="请输入账号">
                        </div>
                        <div class="sjbtn">
                            <li @click="tjjf">添加积分</li>
                        </div>
                    </div>
                </div>
                <div class="info">
                    <li @click="reset">清空登陆</li>
                    <li @click="clean">清空</li>
                    <li @click="saveJSON(infoEnd,`${FileName}.json`)">导出</li>

                </div>
            </div>
    </div>
</template>

<script>
// import { mapMutations } from "vuex"
import { resetState,upbyhand,searchUser,updateScore } from "../../model/user"
import { Toast } from 'vant';
import axios from 'axios'



export default {
    data() {
        return {
            form:{
                username:'',
                password:'',
                usertype:'',
                score:'',
                email:'',
                countdown:'',
                userip:''

            },
            searched:false,
            username:'',
            phone:"",
            score:'',
            FileName: "JsToJsonFile",
            ShowListData: [],
            infoEnd:[]
        }
    },
    computed:{
        //  ...mapMutations(["changeFlag","changeFlag1"]),
    },
    created() {
        // this.getTreeData()

        // this.getInfo()

        //this.ces()
    },
    methods: {
        ces(){
            axios({
				url: '/json/begin.json',
				method: 'get'
			}).then(res => {
				// console.log(res,'77');
                let arr = []
                
                let list = res.data
                list.forEach((item)=>{
                    let obj = {}
                    // console.log(item,555);
                    obj['infoId'] = item.id
                    arr.push(obj)
                })
                console.log(arr,777);
                this.ShowListData = arr
			}).catch( err => err);
        },
        saveJSON(data, filename) {
                if (!data) {
                    alert("保存的数据为空");
                    return;
                }
                if (!filename) filename = "json.json";
                if (typeof data === "object") {
                    data = JSON.stringify(data, undefined, 4);
                }
                var blob = new Blob([data], { type: "text/json" }),
                    e = document.createEvent("MouseEvents"),
                    a = document.createElement("a");
                a.download = filename;
                a.href = window.URL.createObjectURL(blob);
                a.dataset.downloadurl = ["text/json", a.download, a.href].join(":");
                e.initMouseEvent(
                    "click",
                    true,
                    false,
                    window,
                    0,
                    0,
                    0,
                    0,
                    0,
                    false,
                    false,
                    false,
                    false,
                    0,
                    null
                );
                a.dispatchEvent(e);
        },
        getTreeData() {
			axios({
				url: '/json/begin.json',
				method: 'get'
			}).then(res => {
				// console.log(res,'77');
                let arr = []
                
                let list = res.data
                list.forEach((item)=>{
                    let obj = {}
                    // console.log(item,555);
                    obj['infoId'] = item.id
                    arr.push(obj)
                })
                console.log(arr,777);
                this.ShowListData = arr
			}).catch( err => err);
		},
      async  getInfo(){
         await  axios({
				url: '/json/data.json',
				method: 'get'
			}).then(res => {

                console.log(res,'res');
                let data = res.data.results[0].allTests
                console.log(data.length,8888);
                let info = []
              
                  data.forEach((item)=>{
                    if(Object.keys(item)[0]!='' && Object.keys(item)[0]!=undefined){
                    info.push(JSON.parse(Object.keys(item)[0]))

                    }
                })

                info.forEach((item)=>{
                    this.infoEnd.push(item.data)
                })
         
                
              
			}).catch( err => err);
        },

        search(){
            let data = {
                username : this.username
            }
            searchUser(data).then((res)=>{
                console.log(res,777);
                if(res.data.data.length && res.data.data.length == 1){
                     this.searched = true
                     this.form = res.data.data[0]
                     this.phone = this.username
                     Toast.success('查询成功')
                }else{
                    Toast.fail('查询失败')
                }
                
            })
        },
        hysj(){
            let data = {
                username : this.phone
            }
            upbyhand(data).then((res)=>{
                console.log(res);
                Toast.success('升级成功')
            })
        },
        tjjf(){
            let data = {
                username: this.username,
                score: this.score,
            }
            updateScore(data).then((res)=>{
                if(res.status==200){
                    Toast.success('添加成功')
                }
                this.search()
            })
        },
        reset(){
          let data = {
            loginState : 1
          }
          resetState(data).then((res)=>{
            console.log(res);
            Toast.success('修改成功')
          })
        },
        clean(){
            this.phone = ""
            this.username = ""
            this.searched = false
            this.score = ''
        },
        onClickLeft(){
            window.history.back(-1)
        },
        onClickRight(){

        }
    },
   async mounted() {
    // this.ces = true
      // this.ces = true
       // this.changeFlag();
       this.$store.commit('changeFlag')
  },
  destroyed() {
      // this.changeFlag1();
      this.$store.commit('changeFlag1')
  },

}
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}
#container{
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;

}
#container .header{
    width: 100%;
    height: 60px;
    /* color: #ec4899; */
}
#container .logo{
    position: absolute;
    top: 0px;
    /* left: 34%; */
    left: calc(50% - 30px);
    z-index: 99;
    width: 60px;
    height: 60px;
    border-radius: 50%;

}
#container .logo img{
     width: 100%;
     height: 100%;
    border-radius: inherit;
    background-position: 50px 50px;
}
 /deep/.van-nav-bar__text{
   

    color: #ec4899 !important;
}
/deep/.van-nav-bar .van-icon{
    color: #ec4899 !important;
}
/deep/.van-nav-bar__content{
    height: 60px !important;
}
.content .swiper{
    width: 100%;
    height: 250px;
}
/deep/.van-swipe{
    width: 100%;
    height: 250px;
    background-color: #f5f5f5;
}
/deep/.van-swipe img{
    width: 45%;
    height: 100%;
}
#container .content{
    width: 100%;
    height: calc(100% - 60px);
    overflow-y: scroll;
    padding: 0 10px;
    box-sizing: border-box;
    overflow-x: hidden;
}
#container .content .info{
    width: 100%;
    height: 85px;
    padding: 5px;
}
#container .content .info_info{
    width: 100%;
    height: 150px !important;
    padding: 5px;
}
#container .content .info_info .shengji_shengji{
    width: 100%;
    height: 120px !important;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
#container .content .info_info .shengji_shengji .row{
    height: 33.33%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
#container .content .info .title{
    width: 100%;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
    text-align: left;
}
#container .content .info .shengji{
  width: 100%;
  height: 50px;
  font-size: 14px;
}
#container .content .info .shengji .inputvip input{
  width: 70%;
  height: 40px;
  text-indent: 20px;
  float: left;
}
#container .content .info .shengji .sjbtn li{
  margin-left: 2%;
  float: left;
  background-color: #ec4899;
  width: 25%;
  /* width: 50px; */
  height: 43px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  font-size: 16px;
}
#container .content .info li{
  margin-left: 2%;
  float: left;
  background-color: #ec4899;
  width: 25%;
  /* width: 50px; */
  height: 43px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  font-size: 16px;
}
</style>