<template>
    <div id="container">
        <div class="header">
                <van-nav-bar
                title=""
                left-text="返回"
                right-text=""
                left-arrow
                @click-left="onClickLeft"
                @click-right="onClickRight"
                />
            </div>
            <div class="logo">
                <!-- <img src="../../assets/images/title.png" alt=""> -->
                    <img src="../../assets/images/uugai.com-1032126-16719525801216.png" alt="">
            </div>
            <!--  -->
            <div class="content">
               <div class="banner">
                <img src="./imgs/banner.jpg" alt="">
                  <!-- <div class="title">
                        会员导航
                    </div> -->
               </div>
               <div class="list">
                    <div class="list_info">
                        <div class="left_img">
                            <img src="./imgs/ypl.png" alt="">
                        </div>
                        <div class="right_info">
                            <div class="title li">一品楼</div>
                            <!-- <div class="user li" v-if="this.userInfo.usertype>=4">永久账号：ypl16888,zxc16888</div>
                            <div class="user li" v-else>永久账号：(超级、至尊会员可见)</div> -->
                            <!-- <div class="desc li">地址一：https://thua7.info</div>
                            <div class="desc li">地址二：https://thua8.info</div> -->
                            <!-- <div class="user li">永久账号：(超级、至尊会员可见)</div> -->
                            <div class="title li">
                                <div class="goto"  @click="ckzh(0)">查看账号</div>
                                <div class="qsj"  @click="sjhy(0)">升级会员</div>
                                <div class="goto"  @click="ljqw(0)">立即前往</div>
                            </div>
                            <!-- <div class="qsj"  @click="sjhy(0)">升级会员</div>
                            <div class="goto"  @click="ljqw(0)">立即前往</div> -->
                        </div>
                        <!-- <div class="btns">
                            <div class="qsj"  @click="sjhy(0)">升级会员</div>
                            <div class="goto"  @click="ljqw(0)">立即前往</div>
                            <div class="user li">永久账号：(超级、至尊会员可见)</div>

                        </div> -->
                    </div>

                    <div class="list_info">
                        <div class="left_img">
                            <img src="./imgs/lgw.png" alt="">
                        </div>
                        <div class="right_info">
                            <div class="title li">老哥稳</div>
                            <!-- <div class="user li" v-if="this.userInfo.usertype>=4">会员订购中</div>
                            <div class="user li" v-else>会员账号：(超级、至尊会员可见)</div> -->
                            <!-- <div class="desc li">地址一：https://laogewentpi565.vip</div>
                            <div class="desc li">地址二：https://laogewen.vip</div> -->
                            <div class="title li">
                                <div class="goto"  @click="ckzh(1)">查看账号</div>
                                <div class="qsj"  @click="sjhy(1)">升级会员</div>
                                <div class="goto"  @click="ljqw(1)">立即前往</div>
                            </div>
                        </div>
                        <!-- <div class="btns">
                            <div class="qsj" @click="sjhy(1)">升级会员</div>
                            <div class="goto" @click="ljqw(1)">立即前往</div>

                        </div> -->
                    </div>

                    <div class="list_info">
                        <div class="left_img">
                            <img src="./imgs/cgr_1.png" alt="">
                        </div>
                        <div class="right_info">
                            <div class="title li">茶馆儿</div>
                            <!-- <div class="user li" v-if="this.userInfo.usertype>=5">会员订购中</div>
                            <div class="user li" v-else>会员账号：(至尊会员可见)</div> -->
                            <!-- <div class="desc li">地址一：https://0a13d5e2.efmuyibcu.tips/?code=Gbwq</div>
                            <div class="desc li">地址二：https://0a13d5e2.efmuyibcu.tips/?code=Gbwq</div> -->
                            <div class="title li">
                                <div class="goto"  @click="ckzh(2)">查看账号</div>
                                <div class="qsj"  @click="sjhy(2)">升级会员</div>
                                <div class="goto"  @click="ljqw(2)">立即前往</div>
                            </div>
                        </div>
                        <!-- <div class="btns">
                            <div class="qsj" @click="sjhy(2)">升级会员</div>
                            <div class="goto" @click="ljqw(2)">立即前往</div>

                        </div> -->
                    </div>


                    <div class="list_info">
                        <div class="left_img">
                            <img src="./imgs/qmr.jpg" alt="">
                        </div>
                        <div class="right_info">
                            <div class="title li">千妹儿</div>
                            <!-- <div class="user li" v-if="this.userInfo.usertype>=5">会员订购中</div>
                            <div class="user li" v-else>会员账号：(至尊会员可见)</div> -->
                            <!-- <div class="desc li">地址一：https://t.521qm.com/EKDN00</div>
                            <div class="desc li">地址二：https://t.521qm.com/EKDN00</div> -->
                            <div class="title li">
                                <div class="goto"  @click="ckzh(3)">查看账号</div>
                                <div class="qsj"  @click="sjhy(3)">升级会员</div>
                                <div class="goto"  @click="ljqw(3)">立即前往</div>
                            </div>
                        </div>
                        <!-- <div class="btns">
                            <div class="qsj" @click="sjhy(3)">升级会员</div>
                            <div class="goto" @click="ljqw(3)">立即前往</div>

                        </div> -->
                    </div>
               </div>
            
            </div>
    </div>
</template>

<script>
import { Dialog } from 'vant';
import { mapState } from "vuex"
import { Toast } from 'vant';
export default {
    data() {
        return {
            username:'',
            phone:"",
            FileName: "JsToJsonFile",
            ShowListData: [],
            infoEnd:[],
            // userInfo:''
        }
    },
    computed:{
        //  ...mapMutations(["changeFlag","changeFlag1"]),
      
        ...mapState({
            loginStatus : state=>state.user.loginStatus,
            userInfo : state=>state.user.userInfo,
        }),
        loginStatus(){
          return this.$store.state.user.loginStatus
        },
        userInfo(){
          return this.$store.state.user.userInfo
        },
 
    },
    created() {
        // alert(this.loginStatus)
        console.log(this.userInfo,'445');
        // this.getTreeData()

        // this.getInfo()
    },
    methods: {
        sjhy(){
            if(this.loginStatus){
                 Toast.fail('请前往首页升级')
            }else{
                 Toast.fail('请登陆！！！')
            }   
        },
        ljqw(type){
            if(type==0){
               window.location.href="https://www.th2024.cc/"
            }else if(type==1){
                window.location.href="http://qxwd372fy4.com:5002/?aff=548624"
            }else if(type==2){
                window.location.href="https://be7253.nqccujw.com?code=Gbwq"
            }else if(type==3){
                window.location.href="https://t.521qm.com/EKDN00"
            }
        },
        ckzh(type) { 
            if (this.userInfo.usertype && this.userInfo.usertype < 3) {
                Toast.fail('请前往首页升级')
            } else {
                if (this.loginStatus) {
                    if(type==0){
                        // window.location.href="https://www.th2024.cc/"
                        if (this.userInfo.usertype > 3) {
                            Dialog.alert({
                            title: '一品楼会员账号',
                            message: '账号：ypl16888<br>密码：qwe16888',
                            }).then(() => {
                            // on close
                            });
                        } else { 
                            Dialog.alert({
                            title: '温馨提示',
                            message: '仅超级会员及至尊会员可见',
                            }).then(() => {
                            // on close
                            });
                        }
                    }else if(type==1){
                        // window.location.href="http://qxwd372fy4.com:5002/?aff=548624"
                        if (this.userInfo.usertype > 3) {
                            Dialog.alert({
                            title: '老哥稳会员账号',
                            // message: '账号：ypl1688<br>密码：qwe16888',
                            message: "会员采购中,请耐心等待",
                            }).then(() => {
                            // on close
                            });
                        } else { 
                            Dialog.alert({
                            title: '温馨提示',
                            message: '仅超级会员及至尊会员可见',
                            }).then(() => {
                            // on close
                            });
                        }
                        
                    }else if(type==2){
                        // window.location.href="https://be7253.nqccujw.com?code=Gbwq"
                        if (this.userInfo.usertype > 4) {
                            Dialog.alert({
                            title: '茶馆会员账号',
                            // message: '账号：ypl1688<br>密码：qwe16888',
                            message: "会员采购中,请耐心等待",
                            }).then(() => {
                            // on close
                            });
                        } else { 
                            Dialog.alert({
                            title: '温馨提示',
                            message: '仅至尊会员可见',
                            }).then(() => {
                            // on close
                            });
                        }
                        
                        
                    }else if(type==3){
                        // window.location.href="https://t.521qm.com/EKDN00"
                        if (this.userInfo.usertype > 4) {
                            Dialog.alert({
                            title: '千妹会员账号',
                            // message: '账号：ypl1688<br>密码：qwe16888',
                            message: "会员采购中,请耐心等待",
                            }).then(() => {
                            // on close
                            });
                        } else { 
                            Dialog.alert({
                            title: '温馨提示',
                            message: '仅至尊会员可见',
                            }).then(() => {
                            // on close
                            });
                        }
                        
                        
                    }
                } else { 
                    Toast.fail('请登陆！！！')
                }

            }
           
        },


        onClickLeft(){
            window.history.back(-1)
        },
        onClickRight(){

        },
        gz28(){
            window.location.href="https://wcws.xinqia168.com/app/register.php?site_id=1010&topId=108660"
        },
        ww28(){
            window.location.href="http://www.quyutech.cn/desktop"
        }
    },
   async mounted() {
    // this.ces = true
      // this.ces = true
       // this.changeFlag();
       this.$store.commit('changeFlag')
  },
  destroyed() {
      // this.changeFlag1();
      this.$store.commit('changeFlag1')
  },

}
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}
#container{
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;

}
#container .header{
    width: 100%;
    height: 60px;
    /* color: #ec4899; */
}
#container .logo{
    position: absolute;
    top: 0px;
    /* left: 34%; */
    left: calc(50% - 30px);
    z-index: 99;
    width: 60px;
    height: 60px;
    border-radius: 50%;

}
#container .logo img{
     width: 100%;
     height: 100%;
    border-radius: inherit;
    background-position: 50px 50px;
}
 /deep/.van-nav-bar__text{
   

    color: #ec4899 !important;
}
/deep/.van-nav-bar .van-icon{
    color: #ec4899 !important;
}
/deep/.van-nav-bar__content{
    height: 60px !important;
}
.content .swiper{
    width: 100%;
    height: 250px;
}
/deep/.van-swipe{
    width: 100%;
    height: 250px;
    background-color: #f5f5f5;
}
/deep/.van-swipe img{
    width: 45%;
    height: 100%;
}
#container .content{
    width: 100%;
    height: calc(100% - 60px);
    overflow-y: scroll;
    /* padding: 0 10px; */
    box-sizing: border-box;
    overflow-x: hidden;
}
#container .content .banner{
    height: 200px;
    width: 100%;
}
#container .content .banner img{
    /* width: 100%;
    height: 100%; */
    height: 100%;
    
}
#container .content .list{
    height: calc(100% - 200px);
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}
#container .content .list .list_info{
    height: 90px;
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    background-color: #fff;
    border-radius: 10px;
    position: relative;
}
.list_info .left_img{
    width: 80px;
    height: 90px;
    float: left;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.list_info .left_img img{
    /* width: 100%; */
    width: 50px;
}
.list_info .right_info{
    width: calc(100% - 80px);
    height: 90px;
    float: left;
    /* margin-left: 130px; */
    display: flex;
    flex-direction: column;
    font-size: 16px;
    padding: 15px 0;
}
.list_info .right_info .title{
    font-weight: 700;
    color: #ec4899;
    font-size: 18px;
}

.list_info .right_info .user{
    font-weight: 700;
    color: #ec4899;
    font-size: 16px;
}
.list_info .right_info .desc{
    font-weight: 700;
    color: #173659;
    font-size: 14px;
}
.list_info .right_info .li{
    height: 30px;
    display: flex;
}
.list_info .btns{
    font-size: 14px;
    position: absolute;
    width: 200px;
    height: 80px;
    display: flex;
    top: 10px;
    right: 0;
    justify-content: space-evenly;
}
.list_info .btns .qsj{
    width: 75px;
    height: 30px;
    /* float: left; */
    background-color: #ec4899;
    color: #fff;
    border-radius: 20px;
    line-height: 30px;
    cursor: pointer;
}
.list_info .btns .goto{
    width: 75px;
    height: 30px;
    /* float: left; */
    background-color: #ec4899;
    color: #fff;
    border-radius: 20px;
    line-height: 30px;
    cursor: pointer;
}

.list_info .right_info .title .qsj,.goto{
    width: 75px;
    height: 30px;
    /* float: left; */
    background-color: #ec4899;
    color: #fff;
    border-radius: 20px;
    line-height: 30px;
    cursor: pointer;
    margin-right: 8px;
    font-size: 14px;
    font-weight: normal;
}
</style>